import React from 'react'
import ContentWrapper from '../components/contentWrapper'
import Seo from '../components/seo'
// import TextBody from '../components/textBody'

const NotFound = () => {
  return (
    <main>
      <Seo title="Sidan kunde inte hittas - OKG" locale="sv-SE" />
      <ContentWrapper>
        <div className="content-header-simple">
          <h1 className="heading-1">404 - Sidan kunde inte hittas</h1>
        </div>
        {/* <TextBody>Sidan kunde inte hittas</TextBody> */}
      </ContentWrapper>
    </main>
  )
}

export default NotFound
